
/* eslint-disable */
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  reactive,
  ref,
} from "@cloudfun/core";
import { provide, onMounted } from "vue";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import { faL } from "@fortawesome/free-solid-svg-icons";
import Info from "@/views/bike/quantity/infoModal/Main.vue";

export default defineComponent({
  components: {
    Grid,
    Info
  },
  setup() {
    const infoType = ref('station');
    const infoBike = ref(false);
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const stations = ref<any>([]);
    const station = ref<any>(null);
    const areas = ref<any>([]);
    const area = ref<any>(null);
    const timely = ref({
      single: 0,
      family: 0,
      electric: 0,
      motorcycle: 0,
    })
    const ontheway = ref({
      single: 0,
      family: 0,
      electric: 0,
      motorcycle: 0,
    })
    const gridOptions: GridOptions = {
      stripe: false,
      title: "站點既有車量",
      multiselect: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        // custom: true,
        refresh: true
      },
      exportConfig: {
        filename: "站點既有車量",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      columns: [
        {
          field: "Name",
          title: "站點名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          minWidth: 150,
        },
        {
          field: "single",
          title: "單人車",
          showHeaderOverflow: true,
          showOverflow: true,
          minWidth: 100,
          formatter: ({  row }) => {
            if(row.Bikes) {
              var bike = row.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name === "單人電輔車" && e.Bike.Status == 0);
              return bike ? bike.length : 0;
            } else return 0;
          }
        },
        {
          title: "親子車",
          showHeaderOverflow: true,
          showOverflow: true,
          minWidth: 100,
          formatter: ({  row }) => {
            if(row.Bikes) {
              var bike = row.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name === "親子電輔車" && e.Bike.Status == 0);
              return bike ? bike.length : 0;
            } else return 0;
          }
        },
        {
          title: "協力車",
          showHeaderOverflow: true,
          showOverflow: true,
          minWidth: 100,
          formatter: ({  row }) => {
            if(row.Bikes) {
              var bike = row.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name === "協力電輔車" && e.Bike.Status == 0);
              return bike ? bike.length : 0;
            } else return 0;
          }
        },
        {
          title: "重型電動機車",
          showHeaderOverflow: true,
          showOverflow: true,
          minWidth: 100,
          formatter: ({  row }) => {
            if(row.Bikes) {
              var bike = row.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name === "重型電動機車" && e.Bike.Status == 0);
              return bike ? bike.length : 0;
            } else return 0;
          }
        },
        {
          field: "operate",
          title: "操作",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: "150",
          slots: { default: "column-operate" }
        }
      ],
      promises: {
        // query: () => {
        //   return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //       resolve({
        //       data: [
        //           {
        //             station: '向山遊客中心',
        //             single: '40',
        //             family: "4",
        //             electric: "10",
        //           },
        //           {
        //             station: '水社碼頭',
        //             single: '40',
        //             family: "4",
        //             electric: "10",
        //           },
        //         ],
        //         totalCount: 1,
        //       });
        //     }, 100);
        //   });
        // },
        query: model ? async (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          params.condition = new Condition("DataMode", Operator.Equal, 0).and("Published", Operator.Equal, true);
          // if (station.value) {
          //   params.condition.and("Id", Operator.Equal, station.value);
          // }
          if (area.value) {
            params.condition.and("AreaId", Operator.Equal, area.value);
          }
          const result = await model.dispatch('station/query', params);
          timely.value.single = 0;
          timely.value.family = 0;
          timely.value.electric = 0;
          timely.value.motorcycle = 0;
          result.data.forEach((i: any) => {
            timely.value.single += i.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name == "單人電輔車" && e.Bike.Status == 0)?.length ?? 0;
            timely.value.family += i.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name == "親子電輔車" && e.Bike.Status == 0)?.length ?? 0;
            timely.value.electric += i.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name == "協力電輔車" && e.Bike.Status == 0)?.length ?? 0;
            timely.value.motorcycle += i.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name == "重型電動機車" && e.Bike.Status == 0)?.length ?? 0;
          });
          console.log(result)
          return result;
        } : undefined,
        queryAll: model ? () => model.dispatch('station/query') : undefined,
        save: model
          ? (params) => model.dispatch('station/save', params)
          : undefined
      },
      modalConfig: { showFooter: true }
    };
    const gridOptionsWay: GridOptions = {
      stripe: false,
      title: "在途車量",
      multiselect: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        // custom: true,
        refresh: true
      },
      exportConfig: {
        filename: "在途車量",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      columns: [
        {
          field: "Name",
          title: "站點名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          minWidth: 150,
        },
        {
          field: "single",
          title: "單人車",
          showHeaderOverflow: true,
          showOverflow: true,
          minWidth: 100,
          formatter: ({  row }) => {
            if(row.Bikes) {
              var bike = row.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name === "單人電輔車" && (e.Bike.Status == 1 || e.Bike.Status == 3));
              return bike ? bike.length : 0;
            } else return 0;
          }
        },
        {
          title: "親子車",
          showHeaderOverflow: true,
          showOverflow: true,
          minWidth: 100,
          formatter: ({  row }) => {
            if(row.Bikes) {
              var bike = row.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name === "親子電輔車" && (e.Bike.Status == 1 || e.Bike.Status == 3));
              return bike ? bike.length : 0;
            } else return 0;
          }
        },
        {
          title: "協力車",
          showHeaderOverflow: true,
          showOverflow: true,
          minWidth: 100,
          formatter: ({  row }) => {
            if(row.Bikes) {
              var bike = row.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name === "協力電輔車" && (e.Bike.Status == 1 || e.Bike.Status == 3));
              return bike ? bike.length : 0;
            } else return 0;
          }
        },
        {
          title: "重型電動機車",
          showHeaderOverflow: true,
          showOverflow: true,
          minWidth: 100,
          formatter: ({  row }) => {
            if(row.Bikes) {
              var bike = row.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name === "重型電動機車" && (e.Bike.Status == 1 || e.Bike.Status == 3));
              return bike ? bike.length : 0;
            } else return 0;
          }
        },
        {
          field: "operate",
          title: "操作",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: "150",
          slots: { default: "column-operate" }
        }
      ],
      promises: {
        query: model ? async (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          params.condition = new Condition("DataMode", Operator.Equal, 0).and("Published", Operator.Equal, true);
          // if (station.value) {
          //   params.condition.and("Id", Operator.Equal, station.value);
          // }
          if (area.value) {
            params.condition.and("AreaId", Operator.Equal, area.value);
          }
          const result = await model.dispatch('station/query', params);
          ontheway.value.single = 0;
          ontheway.value.family = 0;
          ontheway.value.electric = 0;
          ontheway.value.motorcycle = 0;
          result.data.forEach((i: any) => {
            ontheway.value.single += i.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name == "單人電輔車" && (e.Bike.Status == 1 || e.Bike.Status == 3))?.length ?? 0;
            ontheway.value.family += i.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name == "親子電輔車" && (e.Bike.Status == 1 || e.Bike.Status == 3))?.length ?? 0;
            ontheway.value.electric += i.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name == "協力電輔車" && (e.Bike.Status == 1 || e.Bike.Status == 3))?.length ?? 0;
            ontheway.value.motorcycle += i.Bikes.filter((e: any) => e.Bike.DataMode == 0 && e.Bike.Product && e.Bike.Product.Name == "重型電動機車" && (e.Bike.Status == 1 || e.Bike.Status == 3))?.length ?? 0;
          });
          return result;
        } : undefined,
        queryAll: model ? () => model.dispatch('order/query') : undefined,
        save: model
          ? (params) => model.dispatch('order/save', params)
          : undefined
      },
      modalConfig: { showFooter: true }
    };

    onMounted(async () => {
      const condition = new Condition("Published", Operator.Equal, true);
      areas.value = await model!.dispatch('area/options', { keyword: null, condition: condition });
      //const condition = new Condition("Published", Operator.Equal, true);
      //stations.value = await model!.dispatch('station/options', { keyword: null, condition: condition });
    })

    const infoGrid = ref<any>()
    const infoModal = reactive({
      visible: false,
      selectedRow: {} as any,
      status: 0,
      gridOptions: {
        promises: {
          query: model ? async (params: any) => {
            if(params.sortings) {
              params.sortings.forEach((e: any) => {
                if(e.column && e.column == 'Name') {
                  e.column = 'Product.Name'
                }
              });
            }
            params.condition = new Condition("DataMode", Operator.Equal, 0);
            params.id = infoModal.selectedRow.Id;
            params.status = infoModal.status;
            if(params.sortings && params.sortings.length == 0) params.sortings.push({ column: 'CreatedTime', order: 1 });

            //在途包含"調度中"、"租借中"車輛
            if (infoModal.status == null) {
              var statusCondition = new Condition().and("Status", Operator.Equal, 1).or("Status", Operator.Equal, 3);
              params.condition = params.condition.and(statusCondition);
            }

            const result = await model.dispatch("station/stationBikes", params);
            // console.log(result.data)
            return result;
          } : undefined,
          queryAll: model ? () => model.dispatch('station/stationBikes') : undefined,
        }
      } as GridOptions,
      show(row: any, status: number) {
        infoModal.visible = true;
        infoModal.status = status;
        infoModal.selectedRow = row;
        //console.log(infoModal.selectedRow)
        //if (infoGrid.value) infoGrid.value.refresh();
      }
    })



    return {
      grid,
      gridOptions,
      gridOptionsWay,
      infoType,
      infoBike,
      station,
      stations,
      timely,
      ontheway,
      infoModal,
      infoGrid,
      areas,
      area,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
  },
});
