
/* eslint-disable */
import { defineComponent, toRef, watch, ref } from "vue";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import CloudFun, { Condition, Operator, reactive, Sorting, SortOrder, PropType } from '@cloudfun/core'

export default defineComponent({
  components: {
    Grid,
  },
  props: {
    visible: { type: Boolean, default: true },
    data: {} as any,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const open = toRef(props, "visible");
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const gridOptions: GridOptions = {
      stripe: false,
      title: "車輛庫存",
      multiselect: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        // custom: true,
        // refresh: true
      },
      exportConfig: {
        filename: "車輛庫存",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      height: 400,
      columns: [
        {
          field: "Number",
          title: "車輛編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Status",
          title: "狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.BikeStatus).find(e => e.Value === cellValue)?.Name : undefined,
        },
        {
          field: "Name",
          title: "歸屬產品",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        // {
        //   field: "Bike.Battery",
        //   title: "電量 %",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { showFooter: true }
    };
    watch(
      () => open,
      (current) => {
        console.log("open", current);
      }
    );

    watch(() => props.visible, async (value: any) => {
      if(value) {
        //console.log(grid.value)
        if(grid.value.refresh) grid.value.refresh();
      }
    }, { immediate: true });

    return { 
      open,
      grid,
      gridOptions,
    };
  },
  methods: {
    close() {
      this.$emit("dismiss");
    },
    onDecode(value: any) {
      this.$emit("decode", value);
    },
  },
});

