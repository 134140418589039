import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "overflow-x-auto scrollbar-hidden mb-2" }
const _hoisted_3 = { class: "flex items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_grid = _resolveComponent("grid")!
  const _component_vxe_modal = _resolveComponent("vxe-modal")!

  return (_openBlock(), _createBlock(_component_vxe_modal, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.open) = $event)),
    title: `${_ctx.data?.Name}-既有車輛庫存`,
    showFooter: "",
    width: "90vw",
    transfer: "",
    onClose: _ctx.close,
    onHide: _ctx.close
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
          class: "button text-lg bg-theme-1 text-white"
        }, "確認")
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_grid, _mergeProps({ ref: "grid" }, _ctx.gridOptions), null, 16)
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title", "onClose", "onHide"]))
}